<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toDetail(item, index)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="top_box">
            <div class="avatar_box">
              <img
                v-if="item.avatar == ''"
                src="./img/defult_avatar.png"
                alt=""
              />
              <img v-else :src="item.avatar" alt="" />
            </div>
            <div class="name">{{ item.nickName }}</div>
            <div
              class="delete"
              @click.stop="deleteItem(item.id)"
              v-if="item.createUser == userId || isOwnerStatus == 1"
            >
              删除
            </div>
            <div
              class="toTop"
              @click.stop="toTop(item.id, 1)"
              v-if="isOwnerStatus == 1 && item.isTop == 0"
            >
              置顶
            </div>
            <div
              class="toTop"
              @click.stop="toTop(item.id, 2)"
              v-if="isOwnerStatus == 1 && item.isTop == 1"
            >
              取消置顶
            </div>
          </div>
          <!-- <div class="tag">【{{item.tag}}】</div> -->
          <!-- <div class="simple_content" v-html="item.simpleContent"></div> -->
          <div class="content_box">
            <v-open :showContent="item.topicContent"></v-open>
          </div>
          <div class="pic_box" v-if="item.topicPicture != ''">
            <img :src="item.topicPicture.split(',')[0]" alt="" />
          </div>
          <div class="time_box">
            <div class="time">{{ dateDiff(item.createTime, null) }}</div>
            <div class="detail_box">
              <img class="point_icon" src="./img/zan.png" alt="" />
              <div class="point">{{ item.praiseCount }}</div>
              <img class="num_icon" src="./img/message_icon.png" alt="" />
              <div class="num">{{ item.commentCount }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="submitbtn" @click="toPath()">发表动态</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  selectDynamicsUrl,
  checkIsOwnerUrl,
  deleteDynamicsUrl,
  stickyUrl,
} from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "communitDynamic",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      id: null,
      isOwnerStatus: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.checkIsOwnerFun();
    this.getList();
  },
  methods: {
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (window.location.origin == "https://mapi.xydata.cc") {
        this.$router.push({
          name: "topicTitle",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/associationDynamicDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    toTop(id, type) {
      let params = {
        id: id,
        type: type,
      };
      this.$axios
        .post(`${stickyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            this.init();
          }
        });
    },
    deleteItem(id) {
      let params = {
        ids: id.split(","),
        userId: this.userId,
        type: "17",
      };
      this.$axios
        .post(`${deleteDynamicsUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            this.init();
          }
        });
    },
    toPath() {
      this.$router.push({
        name: "publishingTrends",
        query: {
          id: this.id,
        },
      });
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getList();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        // userId: this.userId,
        topicType: "17",
        associationId: this.id,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${selectDynamicsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    checkIsOwnerFun() {
      //判断当前用户是否为当前社群群主
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${checkIsOwnerUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.isOwnerStatus = res.data;
          }
        });
    },
    //距离现在时间多久
    dateDiff(hisTime, nowTime) {
      if (!arguments.length) return "";

      var arg = arguments,
        now = arg[1] ? arg[1] : new Date().getTime(),
        diffValue = now - new Date(arg[0].replace(/-/g, "/")).getTime(),
        result = "",
        minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        halfamonth = day * 15,
        month = day * 30,
        year = month * 12,
        _year = diffValue / year,
        _month = diffValue / month,
        _week = diffValue / (7 * day),
        _day = diffValue / day,
        _hour = diffValue / hour,
        _min = diffValue / minute;
      if (_year >= 1) result = parseInt(_year) + "年前";
      else if (_month >= 1) result = parseInt(_month) + "个月前";
      else if (_week >= 1) result = parseInt(_week) + "周前";
      else if (_day >= 1) result = parseInt(_day) + "天前";
      else if (_hour >= 1) result = parseInt(_hour) + "个小时前";
      else if (_min >= 1) result = parseInt(_min) + "分钟前";
      else result = "刚刚";
      return result;
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  padding-top: 16px;
  box-sizing: border-box;
  padding-bottom: 150px;

  .submitbtn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fe9615 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .list {
    min-height: 100vh;
    .item {
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      margin-bottom: 16px;

      .time_box {
        width: 100%;
        display: flex;
        margin-top: 22px;
        align-items: center;

        .time {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          flex: 1;
        }

        .detail_box {
          display: flex;
          align-items: center;

          .num_icon {
            margin-left: 38px;
            width: 32px;
            height: 34px;
          }

          .num {
            margin-left: 14px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }

          .point_icon {
            width: 20px;
            height: 20px;
          }

          .point {
            margin-left: 14px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .pic_box {
        width: 686px;
        // height: 334px;
        max-height: calc(100vh / 3 * 2);
        border-radius: 14px;
        margin: 0 auto;
        margin-top: 34px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content_box {
        margin-top: 42px;
      }

      .simple_content {
        margin-top: 34px;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
      }

      .tag {
        font-size: 32px;
        color: #fe9615;
      }

      .top_box {
        width: 100%;
        height: 74px;
        display: flex;
        align-items: center;
        margin-bottom: 34px;

        .toTop {
          margin-left: 20px;
          font-size: 24px;
          color: orange;
          border-radius: 4px;
          border: 2px solid orange;
          padding: 0 4px;
        }

        .delete {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          border: 2px solid rgba(0, 0, 0, 0.5);
          padding: 0 4px;
        }

        .name {
          margin-left: 16px;
          flex: 1;
          font-size: 28px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }

        .avatar_box {
          width: 74px;
          height: 100%;
          border-radius: 50%;
          background: pink;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
